import Alpine from 'alpinejs';
import { API_BASE_URL } from '../../constants';

// Register the upload form component with Alpine
Alpine.data('uploadForm', () => ({
  form: {
    title: '',
    description: '',
    creatorId: '',
    tags: '',
    video: null as File | null,
    thumbnail: null as File | null,
  },
  isUploading: false,
  error: '',
  thumbnailPreview: '',

  handleFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];

      // Validate file type
      if (!file.type.startsWith('video/')) {
        this.error = 'Please select a valid video file';
        return;
      }

      // Validate file size (100MB)
      if (file.size > 100 * 1024 * 1024) {
        this.error = 'File size must be less than 100MB';
        return;
      }

      this.form.video = file;
      this.error = '';
    }
  },

  handleThumbnailChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];

      // Validate file type
      if (!file.type.startsWith('image/')) {
        this.error = 'Please select a valid image file for thumbnail';
        return;
      }

      // Validate file size (5MB)
      if (file.size > 5 * 1024 * 1024) {
        this.error = 'Thumbnail size must be less than 5MB';
        return;
      }

      this.form.thumbnail = file;

      // Create preview URL
      if (this.thumbnailPreview) {
        URL.revokeObjectURL(this.thumbnailPreview);
      }
      this.thumbnailPreview = URL.createObjectURL(file);
      this.error = '';
    }
  },

  removeThumbnail() {
    this.form.thumbnail = null;
    if (this.thumbnailPreview) {
      URL.revokeObjectURL(this.thumbnailPreview);
      this.thumbnailPreview = '';
    }
  },

  async handleSubmit() {
    if (!this.form.video) {
      this.error = 'Please select a video file';
      return;
    }

    this.isUploading = true;
    this.error = '';

    const formData = new FormData();
    formData.append('title', this.form.title);
    formData.append('description', this.form.description);
    formData.append('creatorId', this.form.creatorId);
    formData.append('tags', this.form.tags);
    formData.append('video', this.form.video);

    if (this.form.thumbnail) {
      formData.append('thumbnail', this.form.thumbnail);
    }

    try {
      const response = await fetch(API_BASE_URL + '/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const result = await response.json();

      // Redirect to home page on success
      window.location.href = '/';
    } catch (err) {
      this.error = 'Failed to upload video. Please try again.';
      console.error('Upload error:', err);
    } finally {
      this.isUploading = false;
    }
  },

  destroy() {
    if (this.thumbnailPreview) {
      URL.revokeObjectURL(this.thumbnailPreview);
    }
  }

}));

// Make sure this component is imported in your main index.ts
export default {};
