import { Post } from '../types/types';

export class ClipCompilationService {
  private readonly STORAGE_KEY = 'thc_clips';

  // Load saved clips from localStorage
  loadClips(): Post[] {
    try {
      const saved = localStorage.getItem(this.STORAGE_KEY);
      return saved ? JSON.parse(saved) : [];
    } catch (e) {
      console.error('Error loading clips:', e);
      return [];
    }
  }

  // Save clips to localStorage
  saveClips(clips: Post[]) {
    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(clips));
    } catch (e) {
      console.error('Error saving clips:', e);
    }
  }

  // Add a clip
  addClip(post: Post, currentClips: Post[]): Post[] {
    if (!this.isClipped(post, currentClips)) {
      const updatedClips = [...currentClips, post];
      this.saveClips(updatedClips);
      return updatedClips;
    }
    return currentClips;
  }

  // Remove a clip
  removeClip(post: Post, currentClips: Post[]): Post[] {
    const updatedClips = currentClips.filter(p => p.Title !== post.Title);
    this.saveClips(updatedClips);
    return updatedClips;
  }

  // Check if a post is clipped
  isClipped(post: Post, currentClips: Post[]): boolean {
    return currentClips.some(p => p.Title === post.Title);
  }

  // Reorder clips
  reorderClips(clips: Post[], fromIndex: number, toIndex: number): Post[] {
    const updatedClips = [...clips];
    const [removed] = updatedClips.splice(fromIndex, 1);
    updatedClips.splice(toIndex, 0, removed);
    this.saveClips(updatedClips);
    return updatedClips;
  }
}
