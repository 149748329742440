import { ClipCompilationService } from "../../services/ClipCompilationService";
import { Post } from "../../types/types";

export function initClipsComponent() {
  const clipService = new ClipCompilationService();
  
  return {
    clips: [] as Post[],
    showClipSuccessOverlay: false,
    showExportModal: false,
    isExporting: false,

    initClips() {
      this.clips = clipService.loadClips();
    },

    async addToClips(post: Post) {
      // Add to clips
      this.clips = clipService.addClip(post, this.clips);
      
      // Show success overlay temporarily
      this.showClipSuccessOverlay = true;
      setTimeout(() => {
        this.showClipSuccessOverlay = false;
      }, 2000);

      // Show clipped state in UI
      const clipButton = document.querySelector(`#clip-btn-${post.Title}`);
      if (clipButton) {
        clipButton.classList.add('text-primary');
      }
    },

    removeFromClips(post: Post) {
      this.clips = clipService.removeClip(post, this.clips);
      
      // Update UI
      const clipButton = document.querySelector(`#clip-btn-${post.Title}`);
      if (clipButton) {
        clipButton.classList.remove('text-primary');
      }
    },

    isClipped(post: Post): boolean {
      return clipService.isClipped(post, this.clips);
    },

    handleDragStart(e: DragEvent, index: number) {
      e.dataTransfer?.setData('text/plain', index.toString());
    },

    handleDrop(e: DragEvent, toIndex: number) {
      e.preventDefault();
      const fromIndex = parseInt(e.dataTransfer?.getData('text/plain') || '0');
      this.clips = clipService.reorderClips(this.clips, fromIndex, toIndex);
    },

    allowDrop(e: DragEvent) {
      e.preventDefault();
    }
  };
}
