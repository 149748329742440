import { CreatorsService } from '../../services/CreatorService';

export function creatorsPage() {
  const creatorsService = new CreatorsService();
  
  return {
    creators: [],
    loading: false,
    currentPage: 1,
    hasMore: true,
    itemsPerPage: 10,

    async initCreatorsPage() {
      await this.loadCreators();
    },

    async loadCreators() {
      if (this.loading || !this.hasMore) return;
      
      this.loading = true;
      try {
        const response = await creatorsService.searchCreators('', this.currentPage);
        
        // Add new creators to the list
        this.creators = [...this.creators, ...response.creators];
        
        // Update pagination
        this.hasMore = response.hasMore;
        this.currentPage++;
      } catch (error) {
        console.error('Error loading creators:', error);
      } finally {
        this.loading = false;
      }
    },

    async loadMoreCreators() {
      await this.loadCreators();
    },

    getAccountIcon(accountType: string): string {
      const icons = {
        'youtube': 'i-youtube text-red-500',
        'x-twitter': 'i-twitter text-blue-400',
        'instagram': 'i-instagram text-pink-500',
        'tiktok': 'i-tiktok text-black dark:text-white'
      };
      return icons[accountType] || 'i-link';
    }
  };
}
