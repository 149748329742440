import { PostsService } from '../../services/PostService';
import { CreatorsService } from '../../services/CreatorService';
import { Post } from '../../types/types';

export function initIndexPage(postsService: PostsService, creatorsService: CreatorsService) {
  return {
    posts: [] as Post[],
    loading: false,
    currentPage: 1,
    hasMore: true,
    itemsPerPage: 2,
    likedPosts: new Set<string>(),
    lastScrollPosition: 0,
    scrollDirection: 'down',
    touchStartY: 0,

    async initIndexPage() {
      // Load initial posts
      await this.loadPosts();

      // Initialize liked posts from localStorage
      const savedLikes = localStorage.getItem('likedPosts');
      if (savedLikes) {
        this.likedPosts = new Set(JSON.parse(savedLikes));
      }

      // Enable smooth scrolling for mouse wheel
      this.enableSmoothScroll();
    },

    async loadPosts() {
      if (this.loading || !this.hasMore) return;

      this.loading = true;
      try {
        const response = await postsService.getPosts(this.currentPage, {
          tags: [],
          creators: []
        });

        // Add new posts to the list
        this.posts = [...this.posts, ...response.posts];

        // Update pagination
        this.hasMore = response.hasMore;
        this.currentPage++;

      } catch (error) {
        console.error('Error loading posts:', error);
      } finally {
        this.loading = false;
      }
    },

    enableSmoothScroll() {
      const container = this.$refs.feedContainer;
      if (!container) return;

      // Handle mouse wheel events
      container.addEventListener('wheel', (e: WheelEvent) => {
        e.preventDefault();

        const delta = e.deltaY;
        const currentScroll = container.scrollTop;
        const direction = delta > 0 ? 1 : -1;

        // Find the next snap point
        const viewportHeight = container.clientHeight;
        const currentIndex = Math.round(currentScroll / viewportHeight);
        const targetIndex = currentIndex + direction;
        const targetScroll = targetIndex * viewportHeight;

        // Smooth scroll to the next snap point
        container.scrollTo({
          top: targetScroll,
          behavior: 'smooth'
        });
      }, { passive: false });
    },

    handleScroll(e: Event) {
      const container = e.target as HTMLElement;
      const newScrollPosition = container.scrollTop;

      // Determine scroll direction
      this.scrollDirection = newScrollPosition > this.lastScrollPosition ? 'down' : 'up';
      this.lastScrollPosition = newScrollPosition;

      // Load more posts when near the bottom
      const scrollBottom = container.scrollHeight - container.scrollTop - container.clientHeight;
      if (scrollBottom < 500 && !this.loading && this.hasMore) {
        this.loadPosts();
      }
    },

    togglePlay(video: HTMLVideoElement) {
      if (video.paused) {
        video.muted = true; // Ensure muted for autoplay
        video.play().then(() => {
          // Optionally unmute after successful play
          // video.muted = false;
        }).catch(error => {
          console.error('Error playing video:', error);
        });
      } else {
        video.pause();
      }
    },

    async addToClips(post: Post) {
      try {
        // Get existing clips from localStorage
        const savedClips = localStorage.getItem('clipsPosts');
        const clips: Post[] = savedClips ? JSON.parse(savedClips) : [];

        // Check if post is already clipped
        if (!clips.some(p => p.Title === post.Title)) {
          clips.push(post);
          localStorage.setItem('clipsPosts', JSON.stringify(clips));

          // Show success animation/feedback
          const clipBtn = document.querySelector(`#clip-${post.Title}`);
          if (clipBtn) {
            clipBtn.classList.add('animate-success');
            setTimeout(() => {
              clipBtn.classList.remove('animate-success');
            }, 1000);
          }
        }
      } catch (error) {
        console.error('Error adding to clips:', error);
      }
    },

    getCreatorAvatar(creatorId: string): Promise<string> {
      return creatorId ? creatorsService.getCreatorAvatar(creatorId) : '/api/placeholder/32/32';
    },

    // Touch handling for mobile devices
    handleTouchStart(e: TouchEvent) {
      this.touchStartY = e.touches[0].clientY;
    },

    handleTouchMove(e: TouchEvent) {
      if (!this.touchStartY) return;

      const currentY = e.touches[0].clientY;
      const deltaY = this.touchStartY - currentY;

      // Determine direction and find target snap point
      const direction = deltaY > 0 ? 1 : -1;
      const container = this.$refs.feedContainer;
      const viewportHeight = container.clientHeight;
      const currentIndex = Math.round(container.scrollTop / viewportHeight);
      const targetIndex = currentIndex + direction;
      const targetScroll = targetIndex * viewportHeight;

      // Apply scroll with smooth behavior
      container.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });

      this.touchStartY = 0;
    }
  };
}
