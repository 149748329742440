import { API_BASE_URL } from '../constants';
import { Post } from '../types/types';
import { SearchFilters, SearchResult } from '../types/types';

export class PostsService {
  async getPosts(page: number = 1, filters: SearchFilters = { tags: [], creators: [] }): Promise<SearchResult> {
    try {
      const params = new URLSearchParams({
        page: page.toString(),
        pageSize: '2', // Smaller page size for smoother loading
        filters: JSON.stringify(filters)
      });

      const response = await fetch(`${API_BASE_URL}/posts?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch posts');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching posts:', error);
      return {
        posts: [],
        hasMore: false,
        nextPage: page
      };
    }
  }

  async searchPosts(query: string, page: number = 1, filters: SearchFilters = { tags: [], creators: [] }): Promise<SearchResult> {
    try {
      const params = new URLSearchParams({
        q: query,
        page: page.toString(),
        filters: JSON.stringify(filters)
      });

      const response = await fetch(`${API_BASE_URL}/search?${params}`);
      if (!response.ok) {
        throw new Error('Failed to search posts');
      }

      return await response.json();
    } catch (error) {
      console.error('Error searching posts:', error);
      return {
        posts: [],
        hasMore: false,
        nextPage: page
      };
    }
  }

  async getPostById(id: string): Promise<Post | null> {
    try {
      const response = await fetch(`${API_BASE_URL}/posts/${id}`);
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }
        throw new Error('Failed to fetch post');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching post:', error);
      return null;
    }
  }

  async getPostsByCreator(creatorId: string, page: number = 1): Promise<SearchResult | null> {
    try {
      const response = await fetch(`${API_BASE_URL}/creators/${creatorId}/posts?page=${page}`);
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }
        throw new Error('Failed to fetch post');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching post:', error);
      return null;
    }
  }

  async getSimilarCreatorsPosts(creatorIds: string[], page: number = 1): Promise<SearchResult> {
    try {
      const filters = {
        creators: creatorIds
      };

      const params = new URLSearchParams({
        page: page.toString(),
        filters: JSON.stringify(filters)
      });

      const response = await fetch(`${API_BASE_URL}/posts?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch similar creators posts');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching similar creators posts:', error);
      return {
        posts: [],
        hasMore: false,
        nextPage: page
      };
    }
  }

  async getAllTags(): Promise<string[]> {
    try {
      const response = await fetch(`${API_BASE_URL}/tags`);
      if (!response.ok) {
        throw new Error('Failed to fetch tags');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching tags:', error);
      return [];
    }
  }
}
