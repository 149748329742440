import { API_BASE_URL, CDN_BASE_URL } from '../constants';
import { Creator, Post } from '../types/types';

export class CreatorsService {
  async getCreatorById(id: string): Promise<Creator | null> {
    try {
      const response = await fetch(`${API_BASE_URL}/creators/${id}`);
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }
        throw new Error('Failed to fetch creator');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching creator:', error);
      return null;
    }
  }

  async getCreatorsByTag(tag: string): Promise<Creator[]> {
    try {
      const params = new URLSearchParams({ tag });
      const response = await fetch(`${API_BASE_URL}/creators?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch creators by tag');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching creators by tag:', error);
      return [];
    }
  }

  async getAllCreatorTags(): Promise<string[]> {
    try {
      const response = await fetch(`${API_BASE_URL}/creators/tags`);
      if (!response.ok) {
        throw new Error('Failed to fetch creator tags');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching creator tags:', error);
      return [];
    }
  }

  async getCreatorAvatar(creatorId: string): Promise<string> {
    const creator = await this.getCreatorById(creatorId)

    if (creator) {
      return CDN_BASE_URL + creator.profile.avatar_image_path
    }

    // TODO: default avatar
    return ''
  }

  async searchCreators(query: string, page: number = 1): Promise<{ creators: Creator[], hasMore: boolean }> {
    try {
      const params = new URLSearchParams({
        q: query,
        page: page.toString()
      });
      const response = await fetch(`${API_BASE_URL}/creators?${params}`);
      if (!response.ok) {
        throw new Error('Failed to search creators');
      }
      return await response.json();
    } catch (error) {
      console.error('Error searching creators:', error);
      return {
        creators: [],
        hasMore: false
      };
    }
  }

  async getSimilarCreators(creatorId: string): Promise<Creator[]> {
    try {
      const response = await fetch(`${API_BASE_URL}/creators/${creatorId}/similar`);
      if (!response.ok) {
        throw new Error('Failed to fetch similar creators');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching similar creators:', error);
      return [];
    }
  }

  async getSimilarCreatorsPosts(creatorIds: string[]): Promise<Post[]> {
    try {
      const filters = {
        creators: creatorIds
      };
      
      const params = new URLSearchParams({
        filters: JSON.stringify(filters),
        pageSize: '10'  // Limit to 10 posts from similar creators
      });

      const response = await fetch(`${API_BASE_URL}/posts?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch similar creators posts');
      }
      
      const data = await response.json();
      return data.posts;
    } catch (error) {
      console.error('Error fetching similar creators posts:', error);
      return [];
    }
  }


}
