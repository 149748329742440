import { Post } from '../../types/types/post';
import { SearchFilters } from '../../types/types';

export function initMediaGrid() {
  return {
    posts: [] as Post[],
    loading: false,
    currentPage: 1,
    hasMorePosts: true,
    isLoadingMore: false,
    showLoadMore: false,
    loadMoreTimer: null as number | null,
    skeletonCount: 6,
    selectedFilters: {
      tags: [],
      creators: []
    } as SearchFilters,

    async fetchPosts() {
      if (this.isLoadingMore) return;

      const isInitialLoad = this.posts.length === 0;
      if (isInitialLoad) {
        this.loading = true;
      } else {
        this.isLoadingMore = true;
      }

      try {
        const results = await this.postsService.getPosts(
          this.currentPage, 
          this.selectedFilters
        );

        if (this.loadMoreTimer) {
          clearTimeout(this.loadMoreTimer);
        }

        this.loadMoreTimer = setTimeout(() => {
          this.showLoadMore = true;
        }, 2000);

        this.posts = isInitialLoad ? 
          results.posts : 
          [...this.posts, ...results.posts];
        
        this.hasMorePosts = results.hasMore;
        this.currentPage = results.nextPage;
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        this.isLoadingMore = false;
        this.loading = false;
      }
    },

    handleIntersect() {
      if (!this.isLoadingMore && this.hasMorePosts) {
        this.fetchPosts();
      }
    },

    hidePost(postTitle: string) {
      document.querySelector(`[data-post-id="${postTitle}"]`)?.remove();
    },

    handleMediaGridTouchStart(e: TouchEvent) {
      this.mediaGridTouchStartY = e.touches.length ? e.touches[0].clientY : e.changedTouches[0].clientY;
      this.wasAutoScroll = this.autoScroll;
      this.autoScroll = false;
      this.syncAutoScroll();
    },

    handleMediaGridTouchEnd(e: TouchEvent) {
      const endY = e.touches.length ? e.touches[0].clientY : e.changedTouches[0].clientY;
      if (endY > this.mediaGridTouchStartY) {
        return;
      }

      if (this.wasAutoScroll) {
        setTimeout(() => {
          this.autoScroll = true;
          this.wasAutoScroll = false;
          this.syncAutoScroll();
        }, 1000);
      }
    },
  };
}
