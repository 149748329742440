// src/index.ts
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import { initMediaGrid } from './components/MediaGrid/MediaGrid';
import { fullscreenView } from './components/FullscreenView/FullscreenView';
import { PostsService } from './services/PostService';
import { CreatorsService } from './services/CreatorService';
import { creatorProfile } from './components/CreatorProfile/CreatorProfile';
import './components/UploadForm/UploadForm';
import initTagPage from './components/TagPage/TagPage';
import { creatorsPage } from './components/CreatorsPage/CreatorsPage';
import { initIndexPage } from './components/IndexPage/IndexPage';
import { initClipsComponent } from './components/Clips/Clips';
import { initSearchHandler } from './services/SearchService';
import postDetailsPage from './components/PostDetailsPage/PostDetailsPage';
import { CDN_BASE_URL, THUMBNAIL_CDN_BASE_URL } from './constants';

// Initialize Alpine plugins
Alpine.plugin(intersect);
window.Alpine = Alpine;

document.addEventListener('alpine:init', () => {
  // Initialize services
  const postsService = new PostsService();
  const creatorsService = new CreatorsService();

  Alpine.data('common', function() {
    return {
      CDN_BASE_URL,
      THUMBNAIL_CDN_BASE_URL,

      // Services
      postsService,
      creatorsService,

      // State
      darkMode: localStorage.getItem('darkMode') === 'true' || true,
      autoScroll: localStorage.getItem('autoScroll') === 'true' || false,
      wasAutoScroll: false,
      scrollSpeed: localStorage.getItem('scrollSpeed') || 'normal',
      settingsPanelOpen: false,
      scrollInterval: null,

      currentVideoId: null as string | null,

      // Settings handlers
      toggleDarkMode() {
        this.darkMode = !this.darkMode;
        localStorage.setItem('darkMode', String(this.darkMode));
      },

      toggleAutoScroll() {
        this.autoScroll = !this.autoScroll;
        localStorage.setItem('autoScroll', String(this.autoScroll));
        this.syncAutoScroll();
      },

      updateScrollSpeed() {
        localStorage.setItem('scrollSpeed', this.scrollSpeed);
        this.syncAutoScroll();
      },

      syncAutoScroll() {
        if (this.scrollInterval) {
          clearInterval(this.scrollInterval);
        }

        if (this.autoScroll) {
          const speeds = {
            slow: 1,
            normal: 2,
            fast: 4
          };

          this.scrollInterval = setInterval(() => {
            window.scrollBy({
              top: speeds[this.scrollSpeed],
              behavior: 'smooth'
            });
          }, 50);
        }
      },

      dedupeTags(tags: string[]): string[] {
        return Array.from(new Set(tags));
      },

      async handleVideoIntersect(video: HTMLVideoElement, isIntersecting: boolean) {
        const videoId = video.id.replace('video-', '');

        if (isIntersecting) {
          this.currentVideoId = videoId;

          // Ensure video is muted first for autoplay to work
          video.muted = true;

          try {
            // Reset video position
            video.currentTime = 0;

            // Try to play immediately
            await video.play();

            // Optionally unmute after successful autoplay (if desired)
            // video.muted = false;
          } catch (error) {
            console.warn('Error autoplaying video:', error);

            // Fallback approach - try playing muted
            try {
              video.muted = true;
              await video.play();
            } catch (retryError) {
              console.error('Failed to autoplay even with fallback:', retryError);
            }
          }
        } else {
          if (this.currentVideoId === videoId) {
            this.currentVideoId = null;
          }
          video.pause();
          video.currentTime = 0;
        }
      },

    }
  })

  // Register main app component
  Alpine.data('app', function() {
    return {
      ...initIndexPage(postsService, creatorsService),
      ...initSearchHandler(postsService),

      // Search state
      searchQuery: '',
      selectedTags: [] as string[],
      selectedCreators: [] as string[],
      availableTags: [] as string[],
      searchResults: [],
      showSearchDropdown: false,
      isSearching: false,
      searchTimeout: null as number | null,

      // Initialize
      async init() {
        // Load available tags
        this.availableTags = await this.postsService.getAllTags();

        await this.initFromUrl();
        this.initFullscreenViewFromURL();

        this.initIndexPage()
        this.initClips()

        // Handle browser navigation
        window.addEventListener('popstate', () => {
          this.initFromUrl();
          this.initFullscreenViewFromURL();
        });
      },

      // Search handlers
      async handleSearch() {
        if (this.searchQuery.length < 2) {
          this.showSearchDropdown = false;
          this.searchResults = [];
          return;
        }

        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }

        this.showSearchDropdown = true;
        this.isSearching = true;

        this.searchTimeout = setTimeout(async () => {
          try {
            const filters = {
              tags: this.selectedTags,
              creators: this.selectedCreators
            };
            const results = await this.postsService.searchPosts(this.searchQuery, 1, filters);
            this.searchResults = results.posts;
          } catch (error) {
            console.error('Error searching posts:', error);
            this.searchResults = [];
          } finally {
            this.isSearching = false;
          }
        }, 300);
      },

      toggleTag(tag: string) {
        const index = this.selectedTags.indexOf(tag);
        if (index === -1) {
          this.selectedTags.push(tag);
        } else {
          this.selectedTags.splice(index, 1);
        }
        this.handleSearch();
      },

      toggleCreator(creatorId: string) {
        const index = this.selectedCreators.indexOf(creatorId);
        if (index === -1) {
          this.selectedCreators.push(creatorId);
        } else {
          this.selectedCreators.splice(index, 1);
        }
        this.handleSearch();
      },

      closeSearchDropdown() {
        this.showSearchDropdown = false;
      },

      // URL handling
      async initFromUrl() {
        const params = new URLSearchParams(window.location.search);
        const creatorId = params.get('creator');
        if (creatorId) {
          const creator = await this.creatorsService.getCreatorById(creatorId);
          if (creator) {
            this.currentCreator = creator;
            await this.fetchCreatorPosts();
          }
        }
      },

      // Spread in component functionality
      ...initMediaGrid(),
      ...fullscreenView(postsService, creatorsService),
    };
  });

  // Register creator profile component
  Alpine.data('creatorProfile', function() {
    return {
      ...creatorProfile(creatorsService, postsService),
    }
  });

  Alpine.data('tagPage', () => {
    return {
      ...initTagPage(new PostsService(), new CreatorsService()),
    };
  });

  Alpine.data('creatorsPage', () => {
    return creatorsPage()
  });

  Alpine.data('myClipsPage', () => {
    return initClipsComponent()
  });

  Alpine.data('postDetailsPage', () => {
    return postDetailsPage();
  });

});

Alpine.start();
