import { Creator, Post } from '../../types/types';
import { PostsService } from '../../services/PostService';
import { CreatorsService } from '../../services/CreatorService';

export function fullscreenView(postsService: PostsService, creatorsService: CreatorsService) {
  return {
    fullscreenPost: null as Post | null,
    creator: null as Creator | null,
    creatorPosts: [] as Post[],
    isLoadingCreatorPosts: false,
    isLoadingCreator: false,
    touchStartY: 0,
    touchDeltaY: 0,
    showClipsPageLink: false,

    async initFullscreenViewFromURL() {
      const params = new URLSearchParams(window.location.search);
      const postId = params.get('post');
      if (postId) {
        try {
          const post = await postsService.getPostById(postId);
          if (post) {
            this.fullscreenPost = post;
            await this.loadCreatorData();
            this.lockBody();
          }
        } catch (error) {
          console.error('Error loading post:', error);
        }
      }
    },

    async loadCreatorData() {
      if (!this.fullscreenPost) return;

      try {
        this.isLoadingCreator = true;
        // Load creator data
        const creator = await creatorsService.getCreatorById(this.fullscreenPost.CreatorID);
        this.creator = creator;

        // Load creator posts
        this.isLoadingCreatorPosts = true;
        const result = await postsService.getPostsByCreator(this.fullscreenPost.CreatorID);
        if (result) {
          this.creatorPosts = result.posts.filter(p => p.Title !== this.fullscreenPost?.Title);
        }
      } catch (error) {
        console.error('Error loading creator data:', error);
      } finally {
        this.isLoadingCreator = false;
        this.isLoadingCreatorPosts = false;
      }
    },

    async openFullscreen(post: Post) {
      const container = document.querySelector('#fullscreen-container');
      if (container) {
        container.style.transform = '';
      }

      this.showClipsPageLink = false;
      this.fullscreenPost = post;
      this.updateURL(post);
      await this.loadCreatorData();

      document.getElementById('fullscreen-content')?.scroll({ top: 0 });
      this.lockBody();
    },

    lockBody() {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.dataset.scrollY = window.scrollY.toString();
    },

    unlockBody() {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    },

    closeFullscreen(container?: HTMLElement) {
      if (container) {
        container.style.transform = '';
      }
      this.touchStartY = 0;
      this.touchDeltaY = 0;
      this.fullscreenPost = null;
      this.creator = null;
      this.creatorPosts = [];
      this.updateURL(null);

      this.unlockBody();
      const scrollY = parseInt(document.body.dataset.scrollY || '0');
      window.scrollTo(0, scrollY);
    },

    updateURL(post: Post | null) {
      const params = new URLSearchParams(window.location.search);
      if (post) {
        params.set('post', post.Title);
      } else {
        params.delete('post');
      }

      const queryString = params.toString();
      const newUrl = `${window.location.pathname}${queryString ? '?' + queryString : ''}`;

      if (newUrl !== window.location.href) {
        window.history.pushState({}, '', newUrl);
      }
    },

    handleTouchStart(e: TouchEvent) {
      this.touchStartY = e.touches[0].clientY;
      this.touchDeltaY = 0;
    },

    handleTouchMove(e: TouchEvent) {
      if (!this.touchStartY) return;

      const contentScroll = document.getElementById('fullscreen-content')?.scrollTop;
      if (contentScroll && contentScroll > 0) {
        return;
      }

      this.touchDeltaY = e.touches[0].clientY - this.touchStartY;
      if (this.touchDeltaY > 0) {
        e.preventDefault();
        const container = e.currentTarget as HTMLElement;
        container.style.transform = `translateY(${this.touchDeltaY}px)`;
      }
    },

    handleTouchEnd(e: TouchEvent) {
      const container = e.currentTarget as HTMLElement;
      if (this.touchDeltaY > 100) {
        this.closeFullscreen(container);
      } else {
        container.style.transform = 'translateY(0)';
      }
      this.touchStartY = 0;
      this.touchDeltaY = 0;
    }
  };
}
