import { CreatorsService } from '../../services/CreatorService';
import { PostsService } from '../../services/PostService';
import { Creator, Post } from '../../types/types';

export function creatorProfile(creatorsService: CreatorsService, postsService: PostsService) {
  return {
    creator: null as Creator | null,
    creatorPosts: [] as Post[],
    similarCreators: [] as Creator[],
    similarCreatorsPosts: [] as Post[],
    loading: true,
    currentPage: 1,
    hasMorePosts: true,
    isLoadingMore: false,
    isLoadingSimilar: false,
    hideSimilarCreators: false,
    currentVideoId: null as string | null,
    touchStartY: 0,
    lastScrollPosition: 0,
    hasLoadedSimilar: false,

    async initCreatorProfile() {
      // Initialize with empty arrays to prevent undefined errors
      this.similarCreators = [];
      this.similarCreatorsPosts = [];

      const creatorId = window.location.pathname.split("/creator/")[1];
      if (creatorId) {
        await this.loadCreator(creatorId);
        await this.loadSimilarCreators();
      }
    },

    async loadCreator(creatorId: string) {
      this.loading = true;
      try {
        this.creator = await creatorsService.getCreatorById(creatorId);
        if (this.creator) {
          await this.loadCreatorPosts();
        }
      } catch (error) {
        console.error('Error loading creator:', error);
      } finally {
        this.loading = false;
      }
    },

    async loadCreatorPosts() {
      if (!this.creator || this.isLoadingMore) return;

      this.isLoadingMore = true;
      try {
        const results = await postsService.getPostsByCreator(
          this.creator.id,
          this.currentPage
        );

        if (results?.posts) {
          this.creatorPosts = this.currentPage === 1 ?
            results.posts :
            [...this.creatorPosts, ...results.posts];

          this.hasMorePosts = results.hasMore;
          this.currentPage = results.nextPage;
        }
      } catch (error) {
        console.error('Error loading creator posts:', error);
      } finally {
        this.isLoadingMore = false;
      }
    },

    async loadSimilarCreators() {
      if (!this.creator || this.isLoadingSimilar || this.hasLoadedSimilar) return;

      this.isLoadingSimilar = true;
      try {
        // Load similar creators
        this.similarCreators = await creatorsService.getSimilarCreators(this.creator.id);

        if (this.similarCreators.length > 0) {
          // Get creator IDs
          const creatorIds = this.similarCreators.map(c => c.id);

          // Load posts from similar creators
          const posts = await creatorsService.getSimilarCreatorsPosts(creatorIds);

          // Shuffle posts and limit to 10
          if (posts) {
            this.similarCreatorsPosts = this.shuffleArray(posts).slice(0, 10);
          }
        }

        this.hasLoadedSimilar = true;
      } catch (error) {
        console.error('Error loading similar creators:', error);
        this.similarCreators = [];
        this.similarCreatorsPosts = [];
      } finally {
        this.isLoadingSimilar = false;
      }
    },

    toggleSimilarCreators() {
      this.hideSimilarCreators = !this.hideSimilarCreators;

      // Save preference
      if (this.creator) {
        localStorage.setItem(`hide_similar_${this.creator.id}`,
          this.hideSimilarCreators.toString());
      }
    },

    shuffleArray<T>(array: T[]): T[] {
      const newArray = [...array];
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
      return newArray;
    },

    handleScroll(e: Event) {
      const container = e.target as HTMLElement;
      const scrollBottom = container.scrollHeight - container.scrollTop - container.clientHeight;

      if (scrollBottom < 500 && !this.isLoadingMore && this.hasMorePosts) {
        this.loadCreatorPosts();
      }
    },

    handleIntersect(entries: IntersectionObserverEntry[]) {
      if (entries && entries[0] && entries[0].isIntersecting && !this.isLoadingMore && this.hasMorePosts) {
        this.loadCreatorPosts();
      }
    },

    getAccountIcon(accountType: string): string {
      const icons = {
        'youtube': 'youtube.svg',
        'x-twitter': 'twitter.svg',
        'instagram': 'instagram.svg'
      };
      return `/static/icons/${icons[accountType] || 'link.svg'}`;
    }
  };
}
