// src/components/TagPage/TagPage.ts
import { Post } from '../../types/types';
import { PostsService } from '../../services/PostService';
import { CreatorsService } from '../../services/CreatorService';

export function initTagPage(postsService: PostsService, creatorsService: CreatorsService) {
  return {
    postsService,
    creatorsService,
    tag: '',
    posts: [] as Post[],
    loading: true,
    hasMorePosts: true,
    isLoadingMore: false,
    currentPage: 1,
    error: null as string | null,
    currentVideoId: null as string | null,
    touchStartY: 0,
    lastScrollPosition: 0,

    async initTagPage() {
      this.initializeTag();
      await this.loadPosts();
      this.enableSmoothScroll();

      window.addEventListener('popstate', () => {
        this.initializeTag();
        this.resetAndLoad();
      });
    },

    initializeTag() {
      const urlTag = window.location.href.split("/tag/")[1].replaceAll('/', '');
      if (!urlTag) {
        this.error = 'No tag specified';
        this.loading = false;
        return;
      }
      this.tag = decodeURIComponent(urlTag);
    },

    async resetAndLoad() {
      this.posts = [];
      this.currentPage = 1;
      this.hasMorePosts = true;
      this.loading = true;
      this.error = null;
      await this.loadPosts();
    },

    handleIntersect(entries: IntersectionObserverEntry[]) {
      if (entries && entries[0] && entries[0].isIntersecting && !this.isLoadingMore && this.hasMorePosts) {
        this.loadPosts();
      }
    },

    async loadPosts() {
      if (this.isLoadingMore || !this.tag) return;

      const isInitialLoad = this.posts.length === 0;

      try {
        if (isInitialLoad) {
          this.loading = true;
        } else {
          this.isLoadingMore = true;
        }

        const filters = {
          tags: [this.tag],
          creators: []
        };

        const results = await this.postsService.getPosts(
          this.currentPage,
          filters
        );

        this.posts = isInitialLoad ?
          results.posts :
          [...this.posts, ...results.posts];

        this.hasMorePosts = results.hasMore;
        this.currentPage = results.nextPage;

        // Preload next video if available
        if (this.currentVideoId) {
          this.preloadNextVideo();
        }

      } catch (error) {
        console.error('Error loading posts for tag:', error);
        this.error = 'Failed to load posts. Please try again.';
      } finally {
        this.loading = false;
        this.isLoadingMore = false;
      }
    },

    enableSmoothScroll() {
      const container = this.$refs.feedContainer;
      if (!container) return;

      container.addEventListener('wheel', (e: WheelEvent) => {
        e.preventDefault();

        const delta = e.deltaY;
        const currentScroll = container.scrollTop;
        const direction = delta > 0 ? 1 : -1;

        const viewportHeight = container.clientHeight;
        const currentIndex = Math.round(currentScroll / viewportHeight);
        const targetIndex = currentIndex + direction;
        const targetScroll = targetIndex * viewportHeight;

        container.scrollTo({
          top: targetScroll,
          behavior: 'smooth'
        });
      }, { passive: false });
    },

    handleVideoIntersect(video: HTMLVideoElement, isIntersecting: boolean) {
      const videoId = video.id.replace('video-', '');

      if (isIntersecting) {
        this.currentVideoId = videoId;
        video.muted = true;

        try {
          video.currentTime = 0;
          video.play().catch(console.error);
        } catch (error) {
          console.error('Error playing video:', error);
        }

        this.preloadNextVideo();
      } else {
        if (this.currentVideoId === videoId) {
          this.currentVideoId = null;
        }
        video.pause();
        video.currentTime = 0;
      }
    },

    preloadNextVideo() {
      if (!this.currentVideoId || !this.posts.length) return;

      const currentIndex = this.posts.findIndex(p => p.Title === this.currentVideoId);
      if (currentIndex === -1 || currentIndex >= this.posts.length - 1) return;

      const nextPost = this.posts[currentIndex + 1];
      if (nextPost?.VideoFilePath) {
        const preloadVideo = document.createElement('video');
        preloadVideo.src = this.CDN_BASE_URL + nextPost.VideoFilePath;
        preloadVideo.preload = 'auto';
        preloadVideo.style.display = 'none';
        preloadVideo.load();

        setTimeout(() => {
          preloadVideo.remove();
        }, 5000);
      }
    },

    handleScroll(e: Event) {
      const container = e.target as HTMLElement;
      const newScrollPosition = container.scrollTop;

      this.scrollDirection = newScrollPosition > this.lastScrollPosition ? 'down' : 'up';
      this.lastScrollPosition = newScrollPosition;

      const scrollBottom = container.scrollHeight - container.scrollTop - container.clientHeight;
      if (scrollBottom < 500 && !this.isLoadingMore && this.hasMorePosts) {
        this.loadPosts();
      }
    },

    handleTouchStart(e: TouchEvent) {
      this.touchStartY = e.touches[0].clientY;
    },

    handleTouchMove(e: TouchEvent) {
      if (!this.touchStartY) return;

      const currentY = e.touches[0].clientY;
      const deltaY = this.touchStartY - currentY;

      const direction = deltaY > 0 ? 1 : -1;
      const container = this.$refs.feedContainer;
      const viewportHeight = container.clientHeight;
      const currentIndex = Math.round(container.scrollTop / viewportHeight);
      const targetIndex = currentIndex + direction;
      const targetScroll = targetIndex * viewportHeight;

      container.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });

      this.touchStartY = 0;
    }
  };
}

export default initTagPage;
