import { Post } from '../../types/types';
import { PostsService } from '../../services/PostService';
import { CDN_BASE_URL } from '../../constants';

export function postDetailsPage() {
  const postsService = new PostsService();

  return {
    CDN_BASE_URL,
    posts: [] as Post[],
    post: null as Post | null,
    loading: true,
    currentPage: 1,
    hasMore: true,
    currentVideoId: null as string | null,
    isLoadingMore: false,

    async initPostDetails() {
      // Get post ID from URL
      const postId = window.location.pathname.split('/post/')[1];

      if (!postId) {
        console.error('No post ID found in URL');
        return;
      }

      try {
        // Load the specific post
        const post = await postsService.getPostById(postId);
        if (!post) {
          console.error('Post not found');
          return;
        }

        // Start with the specified post
        this.posts = [post];
        this.post = post;

        // Load additional posts
        await this.loadMorePosts();
      } catch (error) {
        console.error('Error loading post:', error);
      } finally {
        this.loading = false;
      }
    },

    async loadMorePosts() {
      if (this.isLoadingMore || !this.hasMore) return;

      this.isLoadingMore = true;
      try {
        // Get the tags from the current post for related content
        const currentPost = this.posts[0];
        const filters = {
          tags: currentPost.Tags,
          creators: []
        };

        const results = await postsService.getPosts(
          this.currentPage,
          filters
        );

        // Filter out the current post and posts we already have
        const newPosts = results.posts.filter(post => 
          post.Title !== this.posts[0].Title && 
          !this.posts.some(p => p.Title === post.Title)
        );

        // Add new posts to the list
        this.posts = [...this.posts, ...newPosts];
        
        this.hasMore = results.hasMore;
        this.currentPage = results.nextPage;

        // Preload next video
        this.preloadNextVideo();
      } catch (error) {
        console.error('Error loading more posts:', error);
      } finally {
        this.isLoadingMore = false;
      }
    },

    handleVideoIntersect(video: HTMLVideoElement, isIntersecting: boolean) {
      const videoId = video.id.replace('video-', '');

      if (isIntersecting) {
        this.currentVideoId = videoId;
        video.muted = true;
        
        try {
          video.currentTime = 0;
          video.play().catch(error => {
            console.error('Error playing video:', error);
          });
        } catch (error) {
          console.error('Error handling video intersection:', error);
        }

        //preloadNextVideo();
      } else {
        if (this.currentVideoId === videoId) {
          this.currentVideoId = null;
        }
        video.pause();
        video.currentTime = 0;
      }
    },

    preloadNextVideo() {
      if (!this.currentVideoId || !this.posts.length) return;

      const currentIndex = this.posts.findIndex(p => p.Title === this.currentVideoId);
      if (currentIndex === -1 || currentIndex >= this.posts.length - 1) return;

      const nextPost = this.posts[currentIndex + 1];
      if (nextPost?.VideoFilePath) {
        const preloadVideo = document.createElement('video');
        preloadVideo.src = this.CDN_BASE_URL + nextPost.VideoFilePath;
        preloadVideo.preload = 'auto';
        preloadVideo.style.display = 'none';
        preloadVideo.load();

        setTimeout(() => {
          preloadVideo.remove();
        }, 5000);
      }
    },

    togglePlay(video: HTMLVideoElement) {
      if (video.paused) {
        video.muted = true;
        video.play().catch(console.error);
      } else {
        video.pause();
      }
    },

    handleScroll(e: Event) {
      const container = e.target as HTMLElement;
      const scrollBottom = container.scrollHeight - container.scrollTop - container.clientHeight;
      
      if (scrollBottom < 500 && !this.isLoadingMore && this.hasMore) {
        this.loadMorePosts();
      }
    }
  };
}

export default postDetailsPage;
