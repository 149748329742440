// src/components/Search/SearchHandler.ts
import { PostsService } from './PostService';
import { SearchFilters } from '../types/types';

export function initSearchHandler(postsService: PostsService) {
  return {
    searchQuery: '',
    selectedTags: [] as string[],
    selectedCreators: [] as string[],
    isSearching: false,
    searchTimeout: null as number | null,

    async initSearch() {
      // Get search params from URL
      const params = new URLSearchParams(window.location.search);
      this.searchQuery = params.get('q') || '';
      this.selectedTags = params.getAll('tag');
      this.selectedCreators = params.getAll('creator');

      if (this.searchQuery || this.selectedTags.length || this.selectedCreators.length) {
        await this.performSearch(true);
      }

      // Listen for browser navigation
      window.addEventListener('popstate', async () => {
        const params = new URLSearchParams(window.location.search);
        this.searchQuery = params.get('q') || '';
        this.selectedTags = params.getAll('tag');
        this.selectedCreators = params.getAll('creator');
        await this.performSearch(true);
      });
    },

    doSearch() {
      this.performSearch();
    },

    async performSearch(skipUrlUpdate = false) {
      this.isSearching = true;

      try {
        // Update URL parameters if needed
        if (!skipUrlUpdate) {
          const params = new URLSearchParams();
          if (this.searchQuery) {
            params.set('q', this.searchQuery);
          }
          this.selectedTags.forEach(tag => params.append('tag', tag));
          this.selectedCreators.forEach(creator => params.append('creator', creator));

          const newUrl = `${window.location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
          window.history.pushState({}, '', newUrl);
        }

        // Reset feed state
        this.posts = [];
        this.currentPage = 1;
        this.hasMore = true;

        // Load filtered posts
        const filters: SearchFilters = {
          tags: this.selectedTags,
          creators: this.selectedCreators
        };

        if (this.searchQuery) {
          const results = await postsService.searchPosts(this.searchQuery, 1, filters);
          this.posts = results.posts;
          this.hasMore = results.hasMore;
          this.currentPage = results.nextPage;
        } else {
          await this.fetchPosts();
        }
      } catch (error) {
        console.error('Error performing search:', error);
      } finally {
        this.isSearching = false;
      }
    },

    toggleTag(tag: string) {
      const index = this.selectedTags.indexOf(tag);
      if (index === -1) {
        this.selectedTags.push(tag);
      } else {
        this.selectedTags.splice(index, 1);
      }
      this.doSearch();
    },

    toggleCreator(creatorId: string) {
      const index = this.selectedCreators.indexOf(creatorId);
      if (index === -1) {
        this.selectedCreators.push(creatorId);
      } else {
        this.selectedCreators.splice(index, 1);
      }
      this.doSearch();
    },

    clearSearch() {
      this.searchQuery = '';
      this.selectedTags = [];
      this.selectedCreators = [];
      //this.performSearch();
    }
  };
}
